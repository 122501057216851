export const messages = {
  'es': {

    //LoginPage
    'login.init': 'INICIAR SESIÓN',
    'login.enter': 'INGRESAR',
    'login.msAuth': 'Ingresar con cuenta Microsoft',
    'login.forgot': 'Olvidé mi contraseña',
    'login.user': 'Usuario',
    'login.pass': 'Contraseña',
    'login.close': 'Cerrar Sesión',

    //FORGORPASS
    'forgot.title': '¿HA OLVIDADO SU CLAVE?',
    'forgot.input': 'Ingrese su e-mail',
    'forgot.text': 'Le enviaremos las instrucciones para establecer una nueva clave',
    'forgot.next': 'Continuar',
    'forgot.back': 'Volver',
    'forgot.send': 'ENVIADO!',
    'forgot.text.custom': 'Si no recibe un correo, revise su casilla de spam y asegúrese de haber ingresado el e-mail correcto',

    'campaign.name': 'CAMPAÑAS',
    'campaign.report': 'REPORTES',
    'campaign.Buscar Campaña': 'Buscar Campañas',
    'campaign.Buscar ID Cliente ó Tel': 'Buscar ID Cliente ó Tel',
    'campaign.completed': 'Completitud',
    'campaign.all': 'TODAS',
    'campaign.Programado': 'PROGRAMADO',
    'campaign.En proceso': 'EN PROCESO',
    'campaign.Pausado': 'PAUSADO',
    'campaign.Cerrado': 'CERRADO',
    'campaign.Detained': 'DETENIDO',

    'status.start': 'Iniciar',
    'status.pause': 'Pausar',
    'status.end': 'Finalizar',
    'status.edit': 'Editar',
    'status.detain': 'Detener',

    'stat.Compromiso': 'Compromiso',
    'stat.Gestión promedio': 'Gestión promedio',
    'stat.Contactado': 'Contactado',
    'stat.No contactado': 'No Contactado',
    'stat.Fallido': 'Fallido',

    'column.Managed': 'Gestionado',
    'column.ID': 'ID',
    'column.name': 'Nombre',
    'column.Surname': 'Apellido',
    'column.Product': 'Producto',
    'column.Debt': 'Deuda',
    'column.Expiration': 'Vencimiento',
    'column.Calls': 'Llamadas',
    'column.Commitment': 'Compromiso',
    'column.Condition': 'Estado',
    'column.show': 'Mostrando',
    'column.Export': 'Exportar Datos',

    'case.ID': 'ID Cliente',
    'case.Debt': 'Deuda',
    'case.Product': 'Productos',
    'case.Exp': 'Vto.',
    'case.Detalle': 'DETALLE LLAMADA ',
    'case.call': 'LLAMADAS',

    'newcamp.new': 'Nueva Campaña',
    'newcamp.name': 'Nombre Campaña',
    'newcamp.description': 'Descripción',
    'newcamp.filter-result': 'Filtro de Resultados',
    'newcamp.add': 'Agregar Filtro',
    'newcamp.mode': 'Modo de discado',
    'newcamp.vertical': 'Vertical',
    'newcamp.horizontal': 'Horizontal',
    'newcamp.prioridad': 'Orden de Prioridad Telefónico',
    'newcamp.prioridad.max': 'Máximo Orden de Prioridad Telefónico',
    'newcamp.porcentaje': 'Porcentaje Contactabilidad',
    'newcamp.upload': 'Subir Archivo',
    'newcamp.planilla': 'Planilla Modelo',
    'newcamp.soltar': 'Soltar archivos aquí',
    'newcamp.arrastrar': 'Arrastre aquí el archivo de ',
    'newcamp.next': 'SIGUIENTE',
    'newcamp.cancel': 'Cancelar',
    'newcamp.casos': 'Casos',
    'newcamp.max': 'Máx. Llamadas por caso',
    'newcamp.from': 'Llamar desde las',
    'newcamp.to': 'Hasta las',
    'newcamp.start': 'Inicio Campaña',
    'newcamp.onCloses': 'Finalizar al terminar el día',
    'newcamp.today': 'Hoy',
    'newcamp.program': 'Programar',
    'newcamp.delete': 'Eliminar campaña',
    'newcamp.confirm': 'CONFIRMAR',
    'newcamp.mora': 'Seleccionar ciclo de mora',
    'newcamp.created': '¡CAMPAÑA CREADA!',

    'newcamp.filter.permission': 'Asignar Permiso',
    'newcamp.filter.filter': 'Asignar filtro',
    'newcamp.filter.bot': 'Asignar bot',
    'newcamp.filter.no.options': 'No hay opciones',

    'newcamp.watch': 'VER CAMPAÑA',
    'newcamp.leave': 'SALIR',
    'newcamp.list.cases': 'LISTADO DE CASOS ACTUALIZADOS',
    'newcamp.case.added': '¡CASO AGREGADO!',

    'config.title': 'Configuración de la campaña',
    'config.save': ' GUARDAR',
    'config.discard': 'Descartar Cambios',
    'config.modify': '¡CAMPAÑA MODIFICADA!',
    'config.ok': 'ACEPTAR',

    'report.general': 'General',
    'report.detail': 'Detalle',
    'report.dashboard': 'Dashboards',
    'report.año': 'Año',
    'report.mes': 'Mes',
    'report.semana': 'Semana',
    'report.día': 'Día',
    'report.from': 'Desde',
    'report.to': 'Hasta',
    'report.Descanso': 'Descanso',
    'report.Recibido': 'Recibido',
    'report.Gestionados': 'Gestionados',
    'report.Contactados': 'Contactados',
    'report.Compromisos': 'Compromisos',
    'report.Contactados%': 'Contactados%',
    'report.Compromisos%': 'Compromisos%',
    'report.ID-client': 'ID Clientes Recibidos',
    'report.Day': 'Día',
    'report.ID-client-gest': 'ID Cliente Gestionados',
    'report.Contactabilidad%': 'Contactabilidad(%)',
    'report.Apertura': 'Apertura Descanso',
    'report.Cantidad-ID-Clientes': 'Cantidad ID Clientes',
    'report.Gestionado': 'Gestionado',
    'report.ID': 'ID',
    'report.Nombre': 'Nombre',
    'report.Apellido': 'Apellido',
    'report.Producto': 'Producto',
    'report.Deuda': 'Deuda',
    'report.Vencimiento': 'Vencimiento',
    'report.Llamadas': 'Llamadas',
    'report.Estado': 'Estado',
    'report.Show': 'Mostrando 15 de 15',
    'report.Export': 'Exportar Dados',
    'report.exports': 'Exportaciones',

    'empty.info': 'No hay información disponible',
    'empty.dash': 'No hay ningún dashboard seleccionado',
    'empty.campaign': 'No hay campañas disponibles',
    'empty.call': 'No hay llamadas realizadas',
    'empty.details': 'No hay detalles disponibles',

    'reporting.cases.Received': 'RECIBIDOS {value}',
    'reporting.cases.Processed': 'GESTIONADOS {value}',
    'reporting.cases.successfulCall': 'LLAMADA EXITOSA {value}',
    'reporting.cases.ownerTag': 'TITULAR {value}',
    'reporting.cases.Discarded': 'DESCARTADOS {value}',
    'reporting.cases.unsuccessfulCall': 'LLAMADA FALLIDA {value}',
    'reporting.cases.resting': 'DESCANSO {value}',
    'reporting.cases.ithErrors': 'DATOS ERRÓNEOS {value}',
    'reporting.cases.commitments': 'COMPROMISOS {value}',
    'reporting.cases.noCommitments': 'SIN COMPROMISO {value}',
    'reporting.cases.excuseWithCommitments': 'CON EXCUSA {value}',
    'reporting.cases.excuse': 'EXCUSAS {value}',
    'export.File': 'Archivo',
    'export.Status': 'Estado',
    'export.Filters': 'Filtros del archivo',
    'export.Created_at': 'Fecha de creación',
    'export.download': 'Descargar',
    'export.from': 'Desde',
    'export.to': 'Hasta',
    'export.DONE': 'Disponible',
    'export.RECEIVED_WITH_ERRORS': 'Recibido con Errores',
    'export.IN_PROCESS': 'En proceso',
    'export.RECEIVED': 'Recibido',

    'percentage.COMPLETED': 'Casos completados',
    'percentage.CALLED': 'Casos con al menos una llamada',
    //EXTRA
    'setting.config': 'Configuración',
    'text.campaign': 'CAMPAÑA',
    'filter.all': 'TODOS',
    'table.load.users': 'Cargando usuarios...',




    //ERROR
    'login.error.email': '* Email inválido',
    'login.error.field': '* Campo obligatorio',
    'login.error.date': '* Los datos ingresados no coinciden',
    'login.error.pass-coincidence': '* Las contraseñas no coinciden',
    'newcamp.info.10': 'Ej: indicando 10, llamará a los números que atendieron 10 veces en 100 llamados',
    'newcamp.error.error': 'Ha ocurrido un error',
    'newcamp.error.later': 'Por favor, inténtelo de nuevo más tarde.',
    'newcamp.error.upload': 'El archivo subido contiene errores',
    'newcamp.error.title': '* Ingrese un título',
    'newcamp.error.desc': '* Ingrese una descripción',
    'newcamp.error.int': 'Ingrese un número entero entre 1 y 100',
    'newcamp.error.registros': 'Por favor, revise debajo los registros de auditoría generados para el archivo subido',
    'newcamp.error.info.rango.horario': '* Ingrese un rango de horario válido',
    'newcamp.error.drop': '* Adjunte un archivo',
    'newcamp.error.network.title': 'SIN CONEXIÓN A INTERNET',
    'newcamp.error.network.subtitle': 'Comprueba tu conexión Wi-Fi o de datos móviles, e intenta volver a cargar la campaña.',
    'newcamp.error.file.longname': '* El nombre del archivo no puede superar los 100 caracteres',
    'newcamp.error.file.100': 'El nombre de la planilla no puede superar los 100 caracteres',

    'microsoft.error': 'Error ingresando con su cuenta de Microsoft',
    'microsoft.error.verif': 'Error verificando su subscripción',
    'microsoft.error.success': 'Proceso de subscripción finalizado con éxito',
    'microsoft.error.pending': 'Su subscripción se encuentra pendiente de activación. Una vez finalizado el proceso de aprovisionamiento estará recibiendo un correo a ',
    'microsoft.error.login': 'Error ingresando a su cuenta',
    'microsoft.error.failed': 'Demasiados intentos de inicio de sesión fallidos. Por favor intente nuevamente en unos minutos.',

  },


  'pt-BR': {
    'login.init': 'INICIAR',
    'login.enter': 'EFETUAR LOGIN',
    'login.msAuth': 'Entrar com a conta da Microsoft',
    'login.forgot': 'Esqueci minha senha',
    'login.user': 'Nome de usuário',
    'login.pass': 'senha',
    'login.close': 'fechar Sessão',

    //FORGORPASS
    'forgot.title': 'ESQUECEU SUA SENHA?',
    'forgot.input': 'Digite seu e-mail',
    'forgot.text': 'Nós lhe enviaremos as instruções para estabelecer uma nova chave',
    'forgot.next': 'Continuar',
    'forgot.back': 'Retornar',
    'forgot.send': 'ENVIADO!',
    'forgot.text.custom': 'Se você não receber um e-mail, verifique sua caixa de spam e certifique-se de ter digitado o e-mail correto',


    'campaign.name': 'CAMPANHA',
    'campaign.report': 'RELATORIOS',
    'campaign.Buscar Campaña': 'Pesquisar Campanha',
    'campaign.Buscar ID Cliente ó Tel': 'Pesquisar ID do cliente ou tel',
    'campaign.completed': 'Completude',
    'campaign.all': 'TUDO',
    'campaign.Programado': 'PROGRAMADO',
    'campaign.En proceso': 'EM EXECUÇÃO',
    'campaign.Pausado': 'PAUSA',
    'campaign.Cerrado': 'FECHADO',
    'campaign.Detained': 'DETIDO',

    'status.start': 'Começar',
    'status.pause': 'Pausa',
    'status.end': 'Fechar',
    'status.edit': 'Editar',
    'status.detain': 'Detener',

    'stat.Compromiso': 'Promessa',
    'stat.Gestión promedio': 'Gestão Média',
    'stat.Contactado': 'Contatado',
    'stat.No contactado': 'No Contatado',
    'stat.Fallido': 'Fracassado',

    'column.Managed': 'Data e Hora Gestão',
    'column.ID': 'ID',
    'column.name': 'Nome',
    'column.Surname': 'Sobrenome',
    'column.Product': 'Produtos',
    'column.Debt': 'Dívida',
    'column.Expiration': 'Expiração',
    'column.Calls': 'Chamadas',
    'column.Commitment': 'Promessa',
    'column.Condition': 'Resultado',
    'column.show': 'Mostrando',
    'column.Export': 'Exportar Dados',

    'case.ID': 'ID do Cliente',
    'case.Debt': 'Dívida',
    'case.Product': 'Produtos',
    'case.Exp': 'Exp.',
    'case.Detalle': 'DETALHE CHAMADA ',
    'case.call': 'CHAMADAS',

    'newcamp.new': 'Nova Campanha',
    'newcamp.name': 'Nome Campanha',
    'newcamp.description': 'Descrição',
    'newcamp.filter-result': 'Filtro de resultados',
    'newcamp.add': 'Adicionar filtro',
    'newcamp.mode': 'Modo de discagem',
    'newcamp.vertical': 'Vertical',
    'newcamp.horizontal': 'Horizontal',
    'newcamp.prioridad': 'Ordem de Prioridade de Telefone',
    'newcamp.prioridad.max': 'Ordem de Prioridade de Telefone Mais Alta',
    'newcamp.porcentaje': 'Porcentagem de Contactabilidade',
    'newcamp.upload': 'Subir Arquivo',
    'newcamp.planilla': 'Planilha Modelo',
    'newcamp.soltar': 'Solte os arquivos aqui',
    'newcamp.arrastrar': 'Arraste o arquivo do ',
    'newcamp.next': 'SEGUE',
    'newcamp.cancel': 'Cancelar',
    'newcamp.casos': 'Casos',
    'newcamp.max': 'Máx. Chamadas por caso',
    'newcamp.from': 'Ligue a partir das',
    'newcamp.to': 'Até as',
    'newcamp.start': 'Início de Campanha',
    'newcamp.onCloses': 'Termine no final do dia',
    'newcamp.today': 'Hoje',
    'newcamp.program': 'Programa',
    'newcamp.delete': 'Excluir campanha',
    'newcamp.confirm': 'CONFIRME',
    'newcamp.mora': 'Seleccionar ciclo de retardo',
    'newcamp.created': 'Campanha criada',

    'newcamp.watch': 'VER CAMPANHA',
    'newcamp.leave': 'SAIR',
    'newcamp.list.cases': 'LISTA DE CASOS ATUALIZADOS',
    'newcamp.case.added': 'CASO ADICIONADO!',

    'newcamp.filter.permission': 'Atribuir permissão',
    'newcamp.filter.filter': 'Atribuir filtro',
    'newcamp.filter.bot': 'Atribuir bot',
    'newcamp.filter.no.options': 'Sem opções',

    'config.title': 'Configurações da Campanha',
    'config.save': ' SALVAR',
    'config.discard': 'Descartar Mudanças',
    'config.modify': 'CAMPANHA MODIFICADA!',
    'config.ok': 'ACETAR',

    'report.general': 'Resumo',
    'report.detail': 'Detalhe',
    'report.dashboard': 'Tableros',
    'report.año': 'Ano',
    'report.mes': 'Mês',
    'report.semana': 'Semana',
    'report.día': 'Día',
    'report.from': 'Data de',
    'report.to': 'Até',
    'report.Descanso': 'Descanso',
    'report.Recibido': 'Recebido',
    'report.Gestionados': 'Gerenciados',
    'report.Contactados': 'Contatados',
    'report.Compromisos': 'Promessas',
    'report.Contactados%': 'Contatados%',
    'report.Compromisos%': 'Promessas%',
    'report.ID-client': 'ID dos Clientes Recebidos',
    'report.Day': 'Día',
    'report.ID-client-gest': 'ID dos Clientes Gerenciados',
    'report.Contactabilidad%': 'Contactabilidade(%)',
    'report.Apertura': 'Estados de Descanso ',
    'report.Cantidad-ID-Clientes': 'Quantidade ID Clientes',
    'report.Gestionado': 'Data e Hora Gestão',
    'report.ID': 'ID',
    'report.Nombre': 'Nome',
    'report.Apellido': 'Sobrenome',
    'report.Producto': 'Produtos',
    'report.Deuda': 'Dívida',
    'report.Vencimiento': 'Expiração',
    'report.Llamadas': 'Chamadas',
    'report.Estado': 'Resultado',
    'report.Show': 'Mostrando 15 de 15',
    'report.Export': 'Exportar Dados',
    'report.exports': 'Exporta',

    'empty.info': 'Nenhuma informação disponível',
    'empty.dash': 'Não há painel selecionado',
    'empty.campaign': 'Não há campanhas disponíveis',
    'empty.call': 'Nenhuma chamada feita',
    'empty.details': 'Nenhum detalhe disponível',

    'reporting.cases.Received': 'RECEBIDO {value}',
    'reporting.cases.Processed': 'GERENCIADO {value}',
    'reporting.cases.successfulCall': 'CHAMADA COM SUCESSO {value}',
    'reporting.cases.ownerTag': 'TÍTULO {value}',
    'reporting.cases.Discarded': 'DESCARTADOS {value}',
    'reporting.cases.unsuccessfulCall': 'CHAMADA FALHADA {value}',
    'reporting.cases.resting': 'DESCANSO {value}',
    'reporting.cases.ithErrors': 'DADOS ERRADOS {value}',
    'reporting.cases.commitments': 'COMPROMISSOS {value}',
    'reporting.cases.noCommitments': 'SIN COMPROMISO {value}',
    'reporting.cases.excuseWithCommitments': 'COM DESCULPA {value}',
    'reporting.cases.excuse': 'DESCULPAS {value}',

    'export.File': 'Arquivo',
    'export.Status': 'Estado',
    'export.Filters': 'Filtros de arquivo',
    'export.Created_at': 'Data de criação',
    'export.download': 'Baixar',
    'export.from': 'Desde',
    'export.to': 'Até',
    'export.DONE': 'Acessível',
    'export.RECEIVED_WITH_ERRORS': 'Recebido com Erros',
    'export.IN_PROCESS': 'Em processo',
    'export.RECEIVED': 'Recebido',

    'percentage.COMPLETED': 'Casos concluídos',
    'percentage.CALLED': 'Casos com pelo menos uma chamada',


    //EXTRA
    'setting.config': 'Configurações',
    'text.campaign': 'CAMPAINHA',
    'filter.all': 'TUDO',
    'table.load.users': 'Carregando usuários...',


    //ERROR
    'login.error.email': '* Email inválido',
    'login.error.field': '* O campo obrigatório',
    'login.error.date': '* Os dados inseridos não correspondem',
    'login.error.pass-coincidence': '* As senhas não correspondem',

    'newcamp.info.10': 'Ex: indicando 10, ligará para os números que atenderam 10 vezes em 100 ligações',
    'newcamp.error.error': 'Ocorreu um erro',
    'newcamp.error.later': 'Por favor, tente novamente mais tarde.',
    'newcamp.error.upload': 'O arquivo enviado contém erros',
    'newcamp.error.title': '* Digite um título',
    'newcamp.error.desc': '* Digite uma descrição',
    'newcamp.error.int': 'Insira um número inteiro entre 1 e 100',
    'newcamp.error.registros': 'Revise os registros de auditoria gerados para o arquivo enviado abaixo',
    'newcamp.error.info.rango.horario': '* Insira um intervalo de tempo válido',
    'newcamp.error.drop': '* Anexar um arquivo',
    'newcamp.error.network.title': 'SEM CONEXÃO COM A INTERNET',
    'newcamp.error.network.subtitle': 'Verifique seus dados móveis ou conexão Wi-Fi e tente carregar a campanha novamente.',
    'newcamp.error.file.longname': '* O nome do arquivo não pode exceder 100 caracteres',
    'newcamp.error.file.100': 'O nome da planilha não pode exceder 100 caracteres',



    'microsoft.error': 'Erro ao entrar com sua conta da Microsoft',
    'microsoft.error.verif': 'Erro ao verificar sua assinatura',
    'microsoft.error.success': 'Processo de assinatura concluído com sucesso',
    'microsoft.error.pending': 'Sua assinatura está com ativação pendente. Assim que o processo de provisionamento estiver concluído, você receberá um e-mail para ',
    'microsoft.error.login': 'Erro ao entrar na sua conta',
    'microsoft.error.failed': 'Muitas tentativas de login com falha. Por favor, tente novamente em alguns minutos.',

  },
  'en': {

    //LoginPage
    'login.init': 'LOGIN',
    'login.enter': 'LOGIN',
    'login.msAuth': 'Login with Microsoft account',
    'login.forgot': 'Forgot your Password?',
    'login.user': 'Email or user name',
    'login.pass': 'Password',
    'login.close': 'Sign off',

    //FORGORPASS
    'forgot.title': 'FORGOT YOUR PASSWORD?',
    'forgot.input': 'Enter your email',
    'forgot.text': 'We will send you the instructions to establish a new key',
    'forgot.next': 'Continue',
    'forgot.back': 'Return',
    'forgot.send': 'SENT!',
    'forgot.text.custom': 'If you do not receive an email, check your spam box and make sure you have entered the correct email',

    'login.error.email': '* Invalid email',
    'login.error.field': '* Required',
    'login.error.date': '* The data entered does not match',
    'login.error.pass-coincidence': '* Passwords do not match',
    'login.error.accept': 'ACCEPT',

    'microsoft.error': 'Error signing in with your Microsoft account',
    'microsoft.error.verif': 'Error verifying your subscription',
    'microsoft.error.success': 'Subscription process completed successfully',
    'microsoft.error.pending': 'Your subscription is pending activation.Once the provisioning process is finished, you will be receiving an email to  ',
    'microsoft.error.login': 'Error entering your account',
    'microsoft.error.failed': 'Too many failed login attempts. Please try again in a few minutes.',
  }
};